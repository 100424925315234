<template>
  <label
    :for="'fileInput' + ct"
    class="file-select-label position-relative"
    :class="{ block: block }"
  >
    <slot>
      <i class="fas fa-paperclip mr-10 text-grey" title="Fájl csatolás"></i>
    </slot>
    <input
      :id="'fileInput' + ct"
      class="file-select-input"
      type="file"
      ref="input"
      :accept="accept"
      @change="FileInput"
    />
  </label>
</template>

<script>
import { mapGetters } from 'vuex';
import { GetBase64UrlFromFile } from '../../utils/common';
let ct = 0;
export default {
  name: 'file-select',
  data() {
    return { ct: ct++ };
  },
  mounted() {},
  created() {},
  methods: {
    FileInput() {
      const files = this.$refs.input.files;
      this.FileInputRaw(files);
      this.FileInputBase64(files);
    },
    FileInputRaw(files) {
      this.$emit('input', files);
    },
    async FileInputBase64(files) {
      let filesBase64 = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        let base64 = await GetBase64UrlFromFile(file);
        filesBase64.push(base64);
      }
      this.$emit('inputBase64', filesBase64);
    },
  },
  computed: {
    ...mapGetters({}),
  },
  watch: {},
  props: {
    accept: {
      tyle: String,
      default: '',
    },
    block: {
      tyle: Boolean,
      default: true,
    },
  },
};
</script>
<style scoped>
.file-select-label.block {
  width: 100%;
  height: 100%;
}
.file-select-label {
  margin-bottom: 0;
  cursor: pointer;
}
.file-select-input {
  display: none;
}
.file-select-label::v-deep .file-select-img-preview {
  max-height: 100px;
  object-fit: cover;
}
.file-select-label.block::v-deep .file-select-img-preview {
  width: 100%;
  height: 100%;
}
</style>
